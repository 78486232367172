.home{
    line-height: 35px;
    color: $gray-100;

& .top{
    background-color: $special-dark;
    margin-top: 20px;
    width: 100%;
    padding: 80px 0px;
    border-bottom-left-radius: 20%;
    border-bottom-right-radius: 20%;
}

    & .top p, .middle p{
        font-size: 16px;
        font-weight: bold;
    }
    & img{
        border-radius: 10px;
       
    }

    & button{
        background-color: $red;
        font-weight: bold;
        font-size: 18px;
        border-radius: 10px;
    }

    & .middle{
        background-color: $special-color;
        margin-top: 20px;
        padding: 50px 0px;
        border-top-left-radius: 10%;
        border-top-right-radius: 10%;
    }
    & .bottom{
        color: $gray-900;
        font-weight: normal;
    }

    h2, h4{
        font-weight: bold;
        margin-top: 30px;
    }
    & button{
        color: white;
        margin: 20px 0px;
    }
}

@media(max-width: 768px){
    .home .top{
        border-bottom-left-radius: 10%;
        border-bottom-right-radius: 10%;
    }

    .home{
        text-align: center;

        
    }
}