

.footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: $special-dark;
    display: flex;
    align-items: center;
    height: 500px;

    & .left-side{
        padding: 20px;
    }
   & .left-side div{
        color: $gray-200;
      font-size: 20px;
      line-height: 40px;
     
    }

    & .right-side{
        color: $gray-100;
        background-color: $special-color;
        padding: 20px;
        border-radius: 20px;

       & .input, button{
           height: 50px;
       }
       & button{
           background-color: $red;
           font-size: 18px;
           border-radius: 10px;
           margin-top: 0px;
       }

       @media(max-width: 768px){
           button{
               display: block;
               width: 100%;
               margin-top: 20px;
           }
       }
    }

& .copy-right{
    color: $gray-200; 
}
}

@media(max-width: 768px){
    .footer{
        height: 680px;

        .left-side{
            text-align: left;
            margin-bottom: 10px;
        }

        .right-side{
            max-width: 350px;
            margin: auto;
        }
        & .input, button{
            height: 35px;
        }
    }
}