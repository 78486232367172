

.errors{
    display:flex;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    justify-content: center;
    align-items: center;
    z-index: 999;

    .error-content{
        border-radius: 30px;
        text-align: center;
        background: #F8F8FF;
        z-index: 9999;
        padding: 15px;
        color: brown;
    }
    .btn{
        border-radius: 50px;
    }
  }
  