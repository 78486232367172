
.navbar-1{
    position: fixed;
    color: white;
    background-color: #263238;
    padding: 10px;
    width: 200px;
    height: 100%;

    >ul{
        margin-top: 30px;
    }

    ul{
        height: 100%;
        margin-left: -30px;
    }
    
    ul li ul{
        background-color: #1C2331;
    }
    ul li, li ul li{
        list-style-type: none;
        line-height: 50px;
        border-bottom: 1px solid white;
        cursor: pointer;
        font-weight: 600;
    }
   
    li .children{
        position: relative;
        transition: height 0.4s;
        height: 0;
       li{
           font-weight: 400;
           display: none;
       }
    }
    
    .parent .show{
      height: 200px;
        li{
            display: block;
        }
    }

    .parent .show-first{
       height: 200px;
        li{
            display: block;
        }
    }
    
    .rotate::before{
        content: "\f107";
    }

    .bars{
        position: absolute;
        top: 10px;
        left: 20px;

    }
}