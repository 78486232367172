

.top-nav{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;

    >ul{
        display: flex;
        flex-direction: row;
        list-style: none;
        justify-content: flex-end;
        padding: 10px;

        & li{
            padding: 0px 10px;
            cursor: pointer;
            display: relative;

            span::after{
                content: " ";
                position: absolute;
                 border-width: 7px;
                border-style: solid;
                border-color: black transparent transparent transparent;
                top: 35%;       
            }

            & ul.children-box{
                position: absolute;
                height: 0px;
                list-style: none;
                background-color: rgb(4, 10, 92);
                color: white;
                top: 100%;
                z-index: 1;
                transition: height 0.3s;
               & li{
                   list-style-type: none;
                    line-height: 40px;
                    margin-left: -35px;
               } 
               
            }
           & ul.show{
               height: 300px;
           } 
        }
    }
}

