
input:focus, select:focus, textarea:focus{
    outline-color: $green!important;
  }

.form{
  padding: 30px 20px;
  margin-top: 20px;
  margin: auto;
  background-color: $gray-100;
  border-radius: 10px;
  color: $gray-800;

   .input, button{
    height: 40px;
    border-radius: 10px;
  }
  
   button{
    display: block;
    width: 100%;
    background-color: $red;
    margin-top: 20px;
    color: $gray-200;
  }
  
  button.loading{
    background-color: $gray-300;
    outline-style: none;
  }
}  
.retailor-login{
  color: $blue!important;
  text-decoration: underline;
}

.user-login-form{
  width: 350px;
}

.address-form{
  margin-bottom: 50px;
  h3, h4{
    font-weight: bold;
  }
  label{
    font-weight: bold;
  }
}
@media(max-width: 768px){
  .user-login-form{
    width: 300px;
  }
  .input{
    width: 200px;
  }
}