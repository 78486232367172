
.home-nav{
    height: 55px;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 1px;
    background-color: $gray-100;

    .logo{
        height: 60px;
        margin-top: -15px;
    }
    ul.nav-parent {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

    li{
        list-style: none;
        margin: 5px 15px;
        display: relative;
        font-size: 18px;
        padding: 2px 10px;
       
        ul{
            background-color: $special-color;
            position: absolute;
            width: 150px;
            height: 0px;
            right: 50px;
            color: $gray-100;
            transition: height 0.3s ease-out;
            padding: 0px 15px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            z-index: 1;
            top: 60px;

            li{    
            cursor: pointer;
            width: 100%;
            padding: 7px;
            display: none;
            margin-left: -10px;
            }

            li:hover{
                background-color: $special-dark;
            }
         } 

        ul.show{
           height: 100px;

           li{
               display: block;
           }
             }

        }
      
} 
}