@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;1,300&display=swap');
@import './adminSidebar';
@import './topNav';
@import './variables';
@import './footer';
@import './navbar';
@import './header';
@import './home';
@import './forms';
@import './dashboard';
@import './alerts';


html,
body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  background-color: $gray-200;
}

.layout{
  position: relative;
  padding-bottom: 700px;
  overflow-x: hidden!important;
  position: relative;
  min-height: 100vh;
  padding-top: 30px;
}

*{
  box-sizing: border-box;
  font-family: 'OPen sans', 'Times New Roman', Times, serif;
}

.error{
  color: $red;
}

.login-layout{
  padding-top: 70px;
  
}

.full-loader {
  display:flex;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: hsla(0, 80%, 100%, 0.5);
}

button{
  border: none!important;
  outline: none!important;
  cursor: pointer;
  background-color: inherit;
}

