
.dashboard{
    min-height: 90vh;
    .checkout{
        max-width: 400px;
        line-height: 45px;
        margin: auto;
        background-color: $gray-100;
        border-radius: 10px;
        padding: 30px 40px;
        margin-bottom: 50px;
       

        >div{
           >div{
            border-bottom: 1px solid $gray-200;
           }
        }
        div.with-children{
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.2s ease-out;
            color: $gray-800;

            span{
                margin-right: 10px;
                padding: 0px 5px;
                border-radius: 50%;
                background-color: $gray-200;
            }
        }

        div.show{
            max-height: 400px;
        }

        div.with-children > div{
            border-bottom: 1px solid $gray-200;
         } 

         .pay-btn{
            background-color: #F73145!important;
            border-radius: 10px;
            margin-top: 20px;
            color: $gray-200;
            text-align: center;
            padding: 0px 15px;
         }
        }
   
    div.next{
        display: none;
    }
    span.paid, span.today{
        background-color: #F73145!important;
        color: $gray-200;
    }
}

.loader{
    max-width: 350px;
        line-height: 55px;
        margin: auto;
        border-radius: 10px;
        padding: 30px 40px;
        background-color: $gray-100;
}